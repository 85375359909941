<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-3"
    >
      <div class="d-flex align-items-center mb-3 flex-wrap">
        <div class="mr-2">
          <p>name</p>
          <a-input v-model="name" @keyup.enter="changePage(1)"></a-input>
        </div>
        <div class="mr-2">
          <p>externalRoomId</p>
          <a-input
            v-model="externalRoomId"
            @keyup.enter="changePage(1)"
          ></a-input>
        </div>
        <div class="mr-2" style="width: 200px;">
          <p>Engine</p>
          <a-select
            v-model="engine"
            allow-clear
            style="width: 100%"
            @change="changePage(1)"
          >
            <a-select-option key="twilio" value="twilio"
              >twilio</a-select-option
            >
            <a-select-option key="tokbox" value="tokbox"
              >tokbox</a-select-option
            >
            <a-select-option key="zoom" value="zoom"
              >zoom</a-select-option
            >
            <a-select-option key="whereby" value="whereby"
              >whereby</a-select-option
            >
          </a-select>
        </div>
        <div class="d-flex mr-2">
          <div class="mr-2">
            <p>Start Date</p>
            <a-input
              v-model="startDate"
              type="date"
              class="mr-1"
              placeholder="start YYYY-MM-DD"
              @change="changePage(1)"
            ></a-input>
          </div>
          <div>
            <p>End Date</p>
            <a-input
              v-model="endDate"
              type="date"
              class="mr-1"
              placeholder="end YYYY-MM-DD"
              @change="changePage(1)"
            ></a-input>
          </div>
        </div>
      </div>

      <a-button
        size="large"
        type="primary"
        icon="plus-circle"
        class="mt-3 ml-2"
        @click="toggleEditor"
        >Create room</a-button
      >
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="mr-3">
        <p>Show</p>
        <a-select
          v-model="pagination.pageSize"
          style="width: 80px;"
          @change="changePage(1)"
        >
          <a-select-option
            v-for="(nRow, index) in rowEntries"
            :key="`row-entries-${index}`"
            :value="nRow"
          >
            {{ nRow }}
          </a-select-option>
        </a-select>
      </div>
      <div class="mr-3">
        <p>Attended/Absence Room</p>
        <a-select
          v-model="attendRoom"
          placeholder="All Room"
          style="width: 150px;"
          @change="changePage(1)"
        >
          <a-select-option :value="1">
            Attended
          </a-select-option>
          <a-select-option :value="0">
            Absence
          </a-select-option>
        </a-select>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="roomData"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="loading"
      size="middle"
      @change="onChange"
    >
      <template slot="engine" slot-scope="text, record">
        {{ record.isMainEngine ? text : record.fallbackEngine }}
      </template>
      <template slot="startTime" slot-scope="text">
        {{ text | moment("DD/MM/YYYY HH:mm") }}
      </template>
      <template slot="action" slot-scope="text">
        <router-link
          :to="{ name: 'room-id-participate', params: { id: text } }"
        >
          <a-button type="primary" icon="user" class="m-1 ml-0">
            Edit
          </a-button>
        </router-link>
        <router-link :to="{ name: 'room-id-detail', params: { id: text } }">
          <a-button type="default" icon="info-circle" class="m-1 ml-0">
            Detail
          </a-button>
        </router-link>
        <span>
          <a-popconfirm
            title="Confirm for remove"
            @confirm="() => removeRoom(text)"
          >
            <a-button type="danger" icon="delete" class="m-1">
              Remove
            </a-button>
          </a-popconfirm>
        </span>
      </template>
    </a-table>
    <a-drawer
      title="Create Room"
      width="520"
      placement="right"
      :closable="false"
      :visible="showRoomEditor"
      :after-visible-change="afterVisibleChange"
      @close="toggleEditor"
    >
      <room-editor
        v-if="showRoomEditor"
        :is-update="false"
        @on-success="success"
        @on-fail="createRoomNotifyError"
      />
    </a-drawer>
  </div>
</template>

<script>
import RoomEditor from "@/components/RoomEditor";
import UseRoomTable from "@/composables/room/use-room-table.ts";
import { computed } from "@vue/composition-api";
export default {
  components: { RoomEditor },
  name: "Room",
  setup(props, context) {
    const route = computed(() => context.root.$route);
    const {
      roomData,
      pagination,
      loading,
      columns,
      startDate,
      endDate,
      engine,
      externalRoomId,
      attendRoom,
      name,
      rowEntries,
      showRoomEditor,
      changePage,
      fetchRoom,
      removeRoomById,
      toggleEditor
    } = UseRoomTable(route.value);

    return {
      // UseRoomTable
      roomData,
      pagination,
      loading,
      columns,
      startDate,
      endDate,
      engine,
      externalRoomId,
      attendRoom,
      name,
      rowEntries,
      showRoomEditor,
      changePage,
      fetchRoom,
      removeRoomById,
      toggleEditor
    };
  },
  methods: {
    onChange(page, filters, sorter) {
      console.log("params", page, filters, sorter);
      this.changePage(page.current);
    },
    async afterVisibleChange(visible) {
      if (!visible) {
        await this.fetchRoom();
      }
    },
    createRoomNotifyError(msg) {
      this.$notification.error({
        message: "Fail!",
        description: msg || "",
        duration: 0
      });
    },
    success() {
      this.$message.success("Created Successfull");
      this.toggleEditor();
    },
    removeRoom(id) {
      const res = this.removeRoomById(id);
      if (res) {
        this.$message.success("Remove Successfull");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
  @include font-weight(regular);
}
.btn-room-name {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
