import { toLocalFormat } from "@/helpers/date.helper";
import validationError from "@/helpers/display-error";
import OrganizationService from "@/services/organization.service";
import RoomService, {
  CreateRoomData,
  RoomInfo,
  RoomMetaData
} from "@/services/room.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";
import moment from "moment";

interface UseRoomEditorState {
  room: CreateRoomData;
  selectedRoomId?: string;
  isLoading: boolean;
  validation?: object;
  roomMetaData: RoomMetaData;
}

interface UseRoomEditorParams {
  roomData: RoomInfo;
  emit: any;
}

const useRoomEditor = ({ roomData, emit }: UseRoomEditorParams) => {
  const state = reactive<UseRoomEditorState>({
    room: {
      isMainEngine: 1,
      startTime: toLocalFormat(moment().toISOString()),
      endTime: toLocalFormat(moment().toISOString()),
      metaData: undefined,
      record: false
    },
    roomMetaData: {
      engderDeckGroup: undefined,
      engderType: undefined,
      chatRoomName: undefined,
      haveTrackLesson: false,
      welcomeMessage: undefined,
      testRoom: false,
      country: "th"
    },
    selectedRoomId: undefined,
    isLoading: false,
    validation: undefined
  });

  const createRoom = async () => {
    state.isLoading = true;
    const { data, validation } = await RoomService.createRoom({
      ...state.room,
      metaData: state.roomMetaData
    });
    if (data) {
      emit("on-success");
    } else {
      const displayError = await validationError(validation);
      if (displayError.type === "array") {
        state.validation = displayError.validation;
        emit("on-fail", JSON.stringify(state.validation));
      } else {
        emit("on-fail", displayError.validation);
      }
    }
    state.isLoading = false;
  };
  const updateRoom = async () => {
    state.isLoading = true;
    if (state.selectedRoomId) {
      const { data, validation } = await RoomService.updateRoom(
        { ...state.room, metaData: state.roomMetaData },
        state.selectedRoomId
      );
      state.isLoading = false;
      if (data) {
        emit("on-success");
      } else {
        const displayError = await validationError(validation);
        if (displayError.type === "array") {
          state.validation = displayError.validation;
          emit("on-fail", JSON.stringify(state.validation));
        } else {
          emit("on-fail", displayError.validation);
        }
      }
    }
    state.isLoading = false;
    return false;
  };

  onMounted(async () => {
    if (roomData) {
      state.selectedRoomId = roomData?.id;
      const data = JSON.stringify(roomData);
      state.room = JSON.parse(data) as CreateRoomData;
      state.room.startTime = toLocalFormat(state.room.startTime);
      state.room.endTime = toLocalFormat(state.room.endTime);
      state.roomMetaData = {
        engderType: state.room.metaData?.engderType,
        engderDeckGroup: state.room.metaData?.engderDeckGroup,
        chatRoomName: state.room.metaData?.chatRoomName,
        haveTrackLesson: state.room.metaData?.haveTrackLesson,
        welcomeMessage: state.room.metaData?.welcomeMessage,
        testRoom: state.room.metaData?.testRoom,
        country: state.room.metaData?.country
      };
    } else {
      const data = await OrganizationService.getOrganization();
      const orgStringifyData = JSON.stringify(data);
      const orgData = JSON.parse(orgStringifyData) as CreateRoomData;
      state.room = { ...state.room, ...orgData };
    }
  });

  return { ...toRefs(state), createRoom, updateRoom };
};

export default useRoomEditor;
