import router from "@/router";
import { RoomInfo } from "@/services/room.service";
import RoomService from "@/services/room.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";
import moment from "moment";

interface UseRoomTableState {
  loading: boolean;
  roomData: RoomInfo[];
  pagination: Pagination;
  columns: ColumnsTable[];
  startDate: string;
  endDate: string;
  engine: string;
  name: string;
  externalRoomId: string;
  attendRoom?: number;
  showRoomEditor: boolean;
  rowEntries: number[];
}

interface ColumnsTable {
  title: string;
  dataIndex: string;
  scopedSlots?: object;
}
interface Pagination {
  pageSize: number;
  current: number;
  total: number;
}
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "External Room Id",
    dataIndex: "externalRoomId"
  },
  {
    title: "Engine",
    dataIndex: "engine",
    scopedSlots: { customRender: "engine" }
  },
  {
    title: "Start",
    dataIndex: "startTime",
    scopedSlots: { customRender: "startTime" }
  },
  {
    title: "Action",
    dataIndex: "id",
    scopedSlots: { customRender: "action" }
  }
];
const UseRoomTable = (route: any) => {
  const state = reactive<UseRoomTableState>({
    loading: true,
    roomData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 80
    },
    startDate: "",
    endDate: "",
    engine: "",
    name: "",
    attendRoom: undefined,
    externalRoomId: "",
    showRoomEditor: false,
    columns,
    rowEntries: [10, 20, 50, 100]
  });

  const initFilter = () => {
    if (route.query.page) {
      state.pagination.current = parseInt(route.query.page, undefined);
    }
    if (route.query.perPage) {
      state.pagination.pageSize = parseInt(route.query.perPage, undefined);
    }
    if (route.query.externalRoom) {
      state.externalRoomId = route.query.externalRoom;
    }
    if (route.query.name) {
      state.name = route.query.name;
    }
    if (route.query.engine) {
      state.engine = route.query.engine;
    }
    if (route.query.startDate) {
      state.startDate = route.query.startDate;
    }
    if (route.query.endDate) {
      state.endDate = route.query.endDate;
    }
  };

  const fetchRoom = async () => {
    state.loading = true;
    const filter = {
      perPage: state.pagination.pageSize,
      currPage: state.pagination.current
    };
    const {
      name,
      engine,
      startDate,
      endDate,
      externalRoomId,
      attendRoom
    } = state;
    const { data, total } = await RoomService.getAllRooms({
      ...filter,
      name,
      externalRoomId,
      engine,
      start: moment(startDate).toISOString(),
      end: moment(endDate).toISOString(),
      attendRoom
    });
    state.roomData = data;
    state.pagination.total = total;
    state.loading = false;
  };

  const changePage = async (page: number) => {
    state.pagination.current = page;
    router.push({
      query: {
        page: `${state.pagination.current}`,
        perPage: `${state.pagination.pageSize}`,
        externalRoom: state.externalRoomId,
        name: state.name,
        engine: state.engine,
        startDate: state.startDate,
        endDate: state.endDate
      }
    });
    fetchRoom();
  };

  const toggleEditor = async () => {
    state.showRoomEditor = !state.showRoomEditor;
  };

  const removeRoomById = async (roomId: string) => {
    const res = await RoomService.removeRoomById(roomId);
    if (res) {
      fetchRoom();
      return res;
    }
  };

  onMounted(() => {
    initFilter();
    fetchRoom();
  });

  return {
    ...toRefs(state),
    changePage,
    toggleEditor,
    fetchRoom,
    removeRoomById
  };
};

export default UseRoomTable;
