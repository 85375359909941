
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import useRoomEditor from "@/composables/room/use-room-editor.ts";
// eslint-disable-next-line no-unused-vars
import { RoomInfo } from "@/services/room.service";
import attendTime from "@/mock/attendTime.ts";
import ValidateError from "@/components/common/ValidateError.vue";
import { ref } from "@vue/composition-api";

@Component({ components: { ValidateError } })
export default class RoomEditor extends Vue {
  @Prop()
  private isUpdate!: boolean;

  @Prop()
  private roomData?: RoomInfo;

  @Emit("on-success")
  onSuccess(): void {
    //
  }

  @Emit("on-fail")
  onFail(): void {
    //
  }

  private attendDropdown = attendTime;

  setup(props: { isUpdate: boolean; roomData: RoomInfo }, context: any) {
    const {
      room,
      roomMetaData,
      createRoom,
      updateRoom,
      validation,
      isLoading
    } = useRoomEditor({
      roomData: props.roomData,
      emit: context.emit
    });
    const roomEngine = ref([
      { label: "Twilio", value: "twilio" },
      { label: "Tokbox", value: "tokbox" },
      { label: "Zoom", value: "zoom" },
      { label: "Whereby", value: "whereby" }
    ]);

    return {
      room,
      roomMetaData,
      createRoom,
      updateRoom,
      validation,
      isLoading,
      roomEngine
    };
  }
}
