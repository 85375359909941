import moment from "moment";
import ApiService, { ApiServiceResponse } from "./api.service";
import { RoleInfo } from "./organization.service";
import {
  IZoomSessionListResponse,
  IZoomSessionRecord
} from "@/types/interfaces/zoom.interface";

interface GetAllRoomsParam {
  start?: string;
  end?: string;
  // engine?: "twilio" | "tokbox" | "whereby";
  engine?: string;
  name?: string;
  externalRoomId?: string;
  perPage: number;
  currPage: number;
  attendRoom?: number;
}

export enum EngineType {
  TWILIO = "twilio",
  TOKBOX = "tokbox",
  ZOOM = "zoom",
  WHEREBY = "whereby"
}

export interface RoomInfo {
  id: string;
  name?: string;
  logoPath: string;
  favicon: string;
  color: string;
  supportEmail?: string;
  supportTel?: string;
  docPath?: string;
  metaData?: RoomMetaData;
  exitPath?: string;
  externalRoomId?: string;
  currentEngine?: string;
  isMainEngine?: boolean;
  forceUseWhereby?: boolean;
  startTime?: Date;
  endTime?: Date;
  record?: boolean;
}

export interface RoomInfoResponseData {
  id: string;
  name?: string;
  logoPath: string;
  favicon: string;
  color: string;
  supportEmail?: string;
  supportTel?: string;
  docPath?: string;
  metaData?: any;
  exitPath?: string;
  externalRoomId?: string;
  currentEngine?: string;
  isMainEngine?: boolean;
}
interface GetAllRoomResponse {
  currPage: string;
  data: RoomInfo[];
  perPage: string;
  total: number;
  totalPage: number;
}

export interface CreateRoomData {
  name?: string;
  logoPath?: string;
  favicon?: string;
  color?: string;
  supportEmail?: string;
  supportTel?: string;
  docPath?: string;
  metaData?: RoomMetaData;
  exitPath?: string;
  externalRoomId?: string;
  engine?: string;
  fallbackEngine?: string;
  isMainEngine?: number;
  startTime: string;
  endTime: string;
  attendBefore?: number;
  attendAfter?: number;
  twilioSID?: string;
  tokboxSID?: string;
  record: boolean;
}
export class RoomMetaData {
  engderDeckGroup?: string;
  engderType?: "kids" | "adult";
  chatRoomName?: string;
  haveTrackLesson?: boolean;
  welcomeMessage?: {
    sender?: string | "Chatbot";
    message: string;
  };
  testRoom?: boolean;
  country?: "th" | "vn";
}
export interface ParticipantCapabilities {
  doc: boolean;
  chat: boolean;
  sticker: boolean;
  invisible: boolean;
  whiteBoard: boolean;
  engineControl?: boolean;
  engder?: boolean;
  publishableTracks: {
    cam: boolean;
    mic: boolean;
    screenShare: boolean;
  };
}

export interface ParticipantInfoResponseData {
  name?: string;
  avatar?: string;
  email: string;
  exitPath?: string;
  roomId: string;
  participantId: string;
  participant: {
    id: string;
    email: string;
  };
  role: RoleInfo;
  roleId: string;
  code: string;
  shortAccessLink: string;
  token: string;
  accessLink: string;
}

export interface CreateParticipant {
  roleId?: string;
  email?: string;
  name?: string;
  avatar?: string;
  exitPath?: string;
}

interface EditParticipantPayload {
  name?: string;
  avatar?: string;
  exitPath?: string;
}

export interface TwilioRecordResponse {
  sid: string;
  status: string;
  uniqueName: string;
  endTime: Date;
  duration: number;
  type: string;
  maxParticipants: number;
  videoCodecs: string[];
  mediaRegion: string;
  participants: ParticipantTwilio[];
  composition?: CompositionTwilio;
}

export interface TokboxRecordResponse {
  id: string;
  duration: number;
  event: "archive";
  hasAudio: boolean;
  hasVideo: boolean;
  name?: string;
  outputMode: string;
  partnerId: number;
  password?: string;
  projectId: number;
  reason: string;
  resolution: string;
  sessionId: string;
  sha256sum: string;
  size: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  url: string;
}

interface CompositionTwilio {
  accountSid: string;
  audioSources: string[];
  bitrate: number;
  dateCompleted: Date;
  dateCreated: Date;
  dateDeleted: Date;
  duration: number;
  format: string;
  links?: { media: string };
  resolution: string;
  roomSid: string;
  sid: string;
  size: number;
  status: string;
  trim: boolean;
  url?: string;
  videoLayout: any;
}

interface ParticipantTwilio {
  sid: string;
  roomSid: string;
  status: string;
  identity: string;
  startTime: Date;
  endTime: Date;
  duration: number;
}

export interface LogsResponse {
  id: string;
  action: string;
  currentEngine: EngineType;
  engineAfter: EngineType;
  link?: string;
  comment?: string;
  comments?: string[];
  time: Date;
  userAgent: string;
  room: RoomInfoResponseData;
  participate: ParticipateInfo;
}

interface ParticipateInfo {
  id: string;
  name: string;
  avatar?: string;
  exitPath?: string;
  roomId: string;
  participantId: string;
  role: RoleInfo;
  participant: {
    id: string;
    email: string;
  };
}
export interface GetRoomByParticipantParams {
  id?: string;
  email?: string;
  token?: string;
  code?: string;
}
export interface GetRoomByParticipantResponse {
  id: string;
  name?: string;
  avatar?: string;
  exitPath?: string;
  roomId: string;
  roleId: string;
  participantId: string;
  room: RoomInfo;
  role: RoleInfo;
}

export interface IGetRoomSession {
  roomId: string;
  sessionName?: string;
  start?: string;
  end?: string;
}

export interface IGetZoomRoomRecord {
  roomId: string;
  sessionId: string;
}

export default class RoomService {
  public static async getAllRooms(
    params: GetAllRoomsParam
  ): Promise<GetAllRoomResponse> {
    const { data } = await this.apiService.get("/Partners/Rooms", { params });
    data.data.forEach((roomInfo: RoomInfoResponseData) => {
      roomInfo.metaData = JSON.parse(roomInfo.metaData) as RoomMetaData;
    });
    return data;
  }

  public static async createRoom(
    data: CreateRoomData
  ): Promise<ApiServiceResponse> {
    const metaData =
      data.metaData && !Object.values(data.metaData).some(v => !v)
        ? JSON.stringify({ ...data.metaData })
        : undefined;
    const startTime = moment(data.startTime).toISOString();
    const endTime = moment(data.endTime).toISOString();
    if (data.externalRoomId === "") {
      data.externalRoomId = undefined;
    }
    const payload = {
      ...data,
      record: data.record ? 1 : 0,
      startTime,
      endTime,
      metaData
    };
    return await this.apiService.post("/Partners/Rooms", payload);
  }

  public static async updateRoom(
    data: CreateRoomData,
    roomId: string
  ): Promise<ApiServiceResponse> {
    const metaData =
      data.metaData && Object.values(data.metaData).some(v => v)
        ? JSON.stringify({ ...data.metaData })
        : undefined;
    const startTime = moment(data.startTime).toISOString();
    const endTime = moment(data.endTime).toISOString();
    const payload = {
      ...data,
      record: data.record ? 1 : 0,
      startTime,
      endTime,
      metaData
    };
    return await this.apiService.put(`/Partners/Rooms/${roomId}`, payload);
  }

  public static async getRoomById(roomId: string): Promise<RoomInfo> {
    const { data } = await this.apiService.get(`/Partners/Rooms/${roomId}`);
    data.metaData = JSON.parse(data.metaData) as RoomMetaData;
    return data;
  }

  public static async removeRoomById(roomId: string): Promise<boolean> {
    const { status } = await this.apiService.delete(
      `/Partners/Rooms/${roomId}`
    );
    return status === 200;
  }

  public static async getAllParticipantByRoomId(
    roomId: string
  ): Promise<ParticipantInfoResponseData[]> {
    const { data } = await this.apiService.get(
      `/Partners/Rooms/${roomId}/Participants`
    );
    return data;
  }

  public static async getParticipantLink(
    roomId: string,
    participantId: string
  ): Promise<{ accessLink: string }> {
    const { data } = await this.apiService.get(
      `/Partners/Rooms/${roomId}/Participants/${participantId}/Link`
    );
    return data;
  }

  public static async addParticipantByRoomId(
    roomId: string,
    payload: CreateParticipant[]
  ): Promise<boolean> {
    const { status } = await this.apiService.post(
      `/Partners/Rooms/${roomId}/Participants`,
      payload
    );
    return status === 201;
  }

  public static async editParticipantByRoomId(
    roomId: string,
    participantId: string,
    payload: EditParticipantPayload
  ): Promise<boolean> {
    const { status } = await this.apiService.put(
      `/Partners/Rooms/${roomId}/Participants/${participantId}`,
      payload
    );
    return status === 201;
  }

  public static async removeParticipantByRoomId(
    roomId: string,
    participantId: string
  ): Promise<boolean> {
    const { status } = await this.apiService.delete(
      `/Partners/Rooms/${roomId}/Participants/${participantId}`
    );
    return status === 200;
  }

  public static async getTwilioRecord({
    roomId,
    start,
    end
  }: IGetRoomSession): Promise<TwilioRecordResponse[]> {
    const { data } = await this.apiService.get(
      `/Partners/Rooms/${roomId}/Records/Twilio`,
      {
        params: { start, end }
      }
    );
    return data;
  }

  public static async createTwilioComposition(
    roomId: string,
    roomSid: string
  ): Promise<boolean> {
    const { status } = await this.apiService.post(
      `/Partners/Rooms/${roomId}/Records/Twilio`,
      {
        roomSID: roomSid
      }
    );
    return status === 201;
  }

  public static async removeTwilioComposition(
    roomId: string,
    compositionSid: string
  ): Promise<boolean> {
    const { status } = await this.apiService.delete(
      `/Partners/Rooms/${roomId}/Records/Twilio`,
      {
        data: {
          compositionSID: compositionSid
        }
      }
    );
    return status === 200;
  }

  public static async getTokboxRecord(
    roomId: string
  ): Promise<TokboxRecordResponse[]> {
    const { data } = await this.apiService.get(
      `/Partners/Rooms/${roomId}/Records/Tokbox`
    );
    return data;
  }

  public static async getZoomSessionListbyRoomId({
    roomId,
    start,
    end,
    sessionName
  }: IGetRoomSession): Promise<IZoomSessionListResponse> {
    const { data } = await this.apiService.get(
      `/Partners/Rooms/${roomId}/Sessions/Zoom`,
      {
        params: { start, end, sessionName }
      }
    );
    return data;
  }

  public static async getZoomRecordBySessionId({
    roomId,
    sessionId
  }: IGetZoomRoomRecord): Promise<IZoomSessionRecord> {
    const { data } = await this.apiService.get(
      `/Partners/Rooms/${roomId}/Records/Zoom`,
      {
        params: { sessionId }
      }
    );
    return data;
  }

  public static async getZoomRecordFile(
    downloadUrl: string
  ): Promise<{ downloadUrl?: string }> {
    const { data } = await this.apiService.get(
      `/Partners/Rooms/RecordFiles/Zoom`,
      {
        params: { downloadUrl }
      }
    );
    return data;
  }

  public static async getLogs(roomId: string): Promise<LogsResponse[]> {
    const { data } = await this.apiService.get(`Partners/Rooms/${roomId}/Logs`);
    const res = await data.map((d: LogsResponse) => {
      d.comments = JSON.parse(d.comment || "[]");
      return d;
    });
    return res;
  }

  public static async getRoomByParticipant(
    params: GetRoomByParticipantParams
  ): Promise<GetRoomByParticipantResponse[]> {
    const { data } = await this.apiService.get(`Partners/Participants/Rooms`, {
      params
    });
    return data;
  }

  private static apiService = new ApiService("partner");
}
