<template>
  
    <div>
      <div class="d-flex mb-3">
        <div class="mr-2">
          <p>participantId</p>
          <a-input v-model="participantId" @change="fetchRoom" />
        </div>
        <div class="mr-2">
          <p>email</p>
          <a-input v-model="email" @change="fetchRoom" />
        </div>
        <div class="mr-2">
          <p>token</p>
          <a-input v-model="token" @change="fetchRoom" />
        </div>
        <div class="mr-2">
          <p>code</p>
          <a-input v-model="code" @change="fetchRoom" />
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="rooms"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="loading"
        size="middle"
        @change="onChange"
      >
        <template slot="engine" slot-scope="text, record">
          {{ record.isMainEngine ? text : record.fallbackEngine }}
        </template>
        <template slot="startTime" slot-scope="text">
          {{ text | moment("DD/MM/YYYY HH:mm") }}
        </template>
        <template slot="action" slot-scope="text">
          <router-link
            :to="{ name: 'room-id-participate', params: { id: text } }"
          >
            <a-button type="primary" icon="user" class="m-1 ml-0">
              Edit
            </a-button>
          </router-link>
          <router-link :to="{ name: 'room-id-detail', params: { id: text } }">
            <a-button type="default" icon="info-circle" class="m-1 ml-0">
              Detail
            </a-button>
          </router-link>
          <span>
            <a-popconfirm
              title="Confirm for remove"
              @confirm="() => removeRoom(text)"
            >
              <a-button type="danger" icon="delete" class="m-1">
                Remove
              </a-button>
            </a-popconfirm>
          </span>
        </template>
      </a-table>
    </div>
</template>

<script>
import useFilterRoom from "@/composables/room/use-filter-room.ts";

export default {
  // eslint-disable-next-line no-unused-vars
  setup(context, props) {
    const {
      loading,
      participantId,
      email,
      token,
      code,
      columns,
      rooms,
      pagination,
      fetchRoom,
      removeRoomById,
      changePage
    } = useFilterRoom();
    return {
      loading,
      participantId,
      email,
      token,
      code,
      columns,
      rooms,
      pagination,
      fetchRoom,
      removeRoomById,
      changePage
    };
  },
  methods: {
    removeRoom(id) {
      const res = this.removeRoomById(id);
      if (res) {
        this.$message.success("Remove Successfull");
      }
    },
    // eslint-disable-next-line no-unused-vars
    onChange(page, filters, sorter) {
      this.changePage(page.current);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>