interface DropdownList {
  text: string;
  value: string | number;
}

const attendTime: DropdownList[] = [
  { text: "10", value: 10 },
  { text: "15", value: 15 },
  { text: "20", value: 20 },
  { text: "25", value: 25 },
  { text: "30", value: 30 },
  { text: "45", value: 45 },
  { text: "60", value: 60 }
];

export default attendTime;
