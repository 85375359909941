import RoomService, {
  GetRoomByParticipantResponse
} from "@/services/room.service";
import { reactive, toRefs } from "@vue/composition-api";
interface Pagination {
  pageSize: number;
  current: number;
  total: number;
}

interface UseFilterRoomState {
  loading: boolean;
  participantId?: string;
  email?: string;
  token?: string;
  code?: string;
  rooms: GetRoomByParticipantResponse[];
  columns: any[];
  pagination: Pagination;
}
const columns = [
  {
    title: "Participant",
    dataIndex: "name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "Room Name",
    dataIndex: "room.name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "External Room Id",
    dataIndex: "room.externalRoomId"
  },
  {
    title: "Engine",
    dataIndex: "room.engine",
    scopedSlots: { customRender: "engine" }
  },
  {
    title: "Start",
    dataIndex: "room.startTime",
    scopedSlots: { customRender: "startTime" }
  },
  {
    title: "Action",
    dataIndex: "roomId",
    scopedSlots: { customRender: "action" }
  }
];
const useFilterRoom = () => {
  const state = reactive<UseFilterRoomState>({
    loading: false,
    participantId: undefined,
    email: undefined,
    token: undefined,
    code: undefined,
    rooms: [],
    columns,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 10
    }
  });

  const fetchRoom = async () => {
    state.loading = true;
    const { participantId: id, email, token, code } = state;
    const data = await RoomService.getRoomByParticipant({
      id,
      email,
      token,
      code
    });
    state.rooms = data;
    state.pagination.total = data.length;
    state.loading = false;
  };

  const removeRoomById = async (roomId: string) => {
    const res = await RoomService.removeRoomById(roomId);
    if (res) {
      fetchRoom();
      return res;
    }
  };

  const changePage = (current: number) => {
    state.pagination.current = current;
  };

  return { ...toRefs(state), fetchRoom, removeRoomById, changePage };
};

export default useFilterRoom;
